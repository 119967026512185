<template>
    <div class="godown">
        <div class="down-logo">
            <img src="../assets/images/nav_logo_1.jpg" alt="">
        </div>
         <div class="down-jiesao">
            <div class="xinhao">
                <img src="../assets/images/sgzlogo.png" alt="">
                <div class="xinhao-wenzi">
                    <ul>
                        <li>
                            <p>幻想三国志&nbsp;&nbsp;&nbsp;</p>
                            <span>上线送VIP4、8000元宝</span>
                        </li>
                        <li>
                            <p>版本：1.0.0 </p>
                            <span>大小：326.3</span>
                        </li>
                        <li>
                            <p>日期：2018-06-15</p>
                            <span>次数：3471次下载</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="lianjie">
        <a id="dnbtn" download href="https://kx.sddrbelt.com/huanxsgz.apk" rel="nofollow"  style="background-position: 0px 0px;color:#fff;">高速下载</a>
            </div>
        <div class="down_img">
            <img src="../assets/images/down_1.jpg" alt="">
            <img src="../assets/images/down_2.jpg" alt="">
            <img src="../assets/images/down_3.jpg" alt="">
        </div>
        <div class="down_jieshao">
            <h1>游戏介绍</h1>
            <p>《幻想三国志》以“无尽云海”为背景，以蒸汽异想与奇幻元素为主题，对三国时代的人物、历史事件进行全新的诠释。《幻想三国志》区别于市面上的传统卡牌游戏，引入了征战四方的玩法，玩家一方面可以体验空岛争夺的征服感，另一方面也要竭尽所能捍卫自己已经获取的领土，招募伙伴，与其他玩家一较高下。力求带给给玩家一种杀敌的爽快感——汹涌而来的敌军，以一当十的激烈场面，让玩家肾上腺素的分泌水平一直处于高强度状态。</p>
        </div>

        <div class="down_jieshao">
            <h1>游戏特色</h1>
            <p>1、充值倍率：1:200，月卡类收益翻20倍!</p>
            <p>2、登录游戏即送VIP5、8000元宝</p>
            <p>3、首充送强力坦克：曹仁</p>
            <p>4、开服登录游戏即可免费获得神将：关羽</p>
            <p>5、日常元宝产出上调，不用花钱也能获得海量元宝</p>
            <p>6、升级所需经验减少，升级更爽快</p>
        </div>

        <div class="down_jieshao">
            <h1>充值返利</h1>
            <p>返利仅限单日累计充值，每日24点清零，第二天重新计算累计：</p>
            <p>单日累计充值100-499元，返利30%</p>
            <p>单日累计充值500-999元，返利50%</p>
            <p>单日累计充值1000-2999元，返利80%</p>
            <p>单日累计充值3000-4999元，返利100%</p>
            <p>单日累计充值5000-9999元，返利120%</p>
            <p>单日累计充值10000元以上，返利150%</p>
            <p>1、当日累积充值达到额度即可返利!只限一日累积，不能多日累积。</p>
            <p>2、达到充值返利条件的玩家请联系客服，登记领取奖励。</p>
            <p>3、游戏内不送任何装备和道具!</p>
            <p>PS：充值返利会在2个工作日内发放到玩家邮件内</p>
        </div>

        
        <div class="down_jieshao">
            <div style="color:#333;font-size:26px;line-height:60px;">游戏攻略</div>
            <h1 style="font-size:16px;">连击技巧：</h1>
            <p>不知道大家玩到哪里了，可能还有很多人招式都没练齐吧，那我就先透露一下几个连击强人好了：</p>
            <p>第一人选：默心，不用说了，必必带。杀无赦因其超慢动画成为连招的首选，配上连诛，顶级的话可以达到12段，神丹· 血戮可以放快捷3，不过因为此药只能维持1回合，所以感觉没什么用，古玩仙人那儿换一个不动光轮给她加50命中，因为只有杀无赦招招命中的情况下，默心才能成为连接第1轮攻击与第2轮攻击的桥梁！晶魄可以配戴加命中和段数的。</p>
            <p>第二人选：夏皓，不用说了，必带。身流萤顶级加左系招式各4段，配上弑罗刹顶级9段可以达到13段，另外心剑放快捷3（一般我单体技放1，多体技放2，辅助技放3），可以达到15段左右。由于招式命中率较高，可以适当添加几个加命中的晶魄。加段数的晶魄也要带。</p>
            <p>第三个名额有点争议，有人说给张婕，我建议给啸月。我们来算一下就清楚了，张婕最高连击招是水系全体技九天玄冰7段，啸月的玄冥追魂箭6段，不过啸月的优势有二：辅助技银之杀戮可以加攻击段数为其一；箭法的准度与速度更直接压倒张婕成为第三人选。每次当默心在上面快要慢吞吞的连完招时，啸月的箭法总能很快的接上，然后夏皓再接，成功达成高段。由于UJ弓本身加命中，且其有被动技心眼，所以晶魄带一些加段数的即可。（如果你说因为张婕的UJ旗可以节省50</p>
            <p>%招式回气时间所以选她的话，那好，等我最后来驳倒你- -）银之杀戮放快捷3。</p>
            <p>第四人选：水湘灵。选她纯粹因为她是医生可以招光朐，而且没她根本不好持久战（如果按Y补满的话另当别论）。旱灼大地+金乌之眼可以达到8连击，晶魄带上一点加段数和命中的即可。召唤光朐放快捷3。</p>
            <p>宠物：光朐。蓄气+暴乱之光轻松上10段，而且打出来的血也很可观，都是两位数！很大程度上防止了还没连几回boss就翘辫子的情况发生。蓄气放快捷3。（建议把真气改成999，免得放几招就跑掉- -）</p>
            <p>装备上：四人必带秘鞋· 八脚。看看这双鞋子的属性吧……节省50%招式回气时间！怎么样，张婕可以光荣退役了吧……</p>
            <p>连击方法：第一回合全体按3，这样一来除了水其他人全部杀戮or心剑状态，同时招出光朐；第二回合可以等到光朐蓄气后进行，全体按1；当默心慢吞吞的杀无赦的时候，怎么样，其他人又可以行动了吧？</p>
            <p>连击对象：计宫主三段身·修罗因其强大的防御力与血量成为当之无愧的不二人选！</p>
            <p>就这样连到死吧~~~哈哈哈~~~</p>
            <p>可能还有些地方没讲清楚，有疑问的话可以跟贴问，稍后放出八位数伤害输出技巧- -喝口水先~汗~</p>
        </div>

        <div class="down_jieshao">
            <h1 style="font-size:16px;">伤害技巧：</h1>
            <p>人选除了温铭，其余无所谓，只要能轻易连到14、5连击即可，推荐带夏、默、瞳</p>
            <P>辅助快捷键：夏-心剑-3；默-蛇牙-3；瞳-苍冥-3；苍冥-战龙之心-3；温铭-武神诀-3。（习惯上如此，其实只要放一个位置即可- -）</P>
            <P>攻击快捷键：夏-罗刹-1；默-无赦-1；瞳-无所谓；苍冥-灭天击-1；温铭-天外飞仙-1（必须练满）。</P>
            <P>第1轮，夏皓没事放放心剑，默心没事偷偷东西，瞳影召唤苍冥（其中苍冥的斗气加持必须练满）。温铭放武神诀（黄天剑诀、剑意破山、武神诀必须练满）。</P>
            <P>第2轮，很重要，别急着攻击，等温铭气满了，给他吃神丹·阎罗，同时苍冥对着温铭放战龙之心。</P>
            <P>第3轮，按1吧……</P>
            <P>由于温铭的天外飞仙速度慢，为了防止夏皓等人的连击过早结束，所以最好要带上默心，她的慢招正好给温铭提供机会- -</P>
            <P>接下来是重头戏：装备与晶魄！</P>
            <P>因为主攻手是温铭，所以只讲一下他的……身体状况？</P>
            <P>武器：UJ小鱼干，8洞；晶魄：仙魄·力神*4，每个+50%伤害。（这玩意儿是我打了几遍收集起来的，没耐心的话可以直接改出来- -）</P>
            <P>帽子：灵气紫微冠，+10%伤害；晶魄：张角之魄，命中当然很重要。</P>
            <p>衣服：紫授衣，+5%伤害；晶魄：无所谓，加点防或反弹即可。</p>
            <p>鞋子：无所谓，和大家保持一个速度级即可（就是百位数数字相同- -）。</p>
            <p>配饰：灵气伤追项链，+30%伤害，5洞；晶魄：灵气左使之魄*5，每个有10%几率伤害*3，5个就是一半- -（这么多啊？没办法了，只能靠改了- -）</p>
            <p>力量：加力量的仙丹吃到撑满为止- -直接改999也行~</p>
            <p>以上~除了左使魄与力量比较难以达成之外，其他的都是正常游戏中可以实现的（左使魄加的是几率，所以如果带1个的话，也能实现，就是比较难罢了；力量上其实也差不了多少，不过为了追求极限还是999稍微稳当）。</p>
            <p>算一下吧：招式上，天外飞仙；被动技，黄天+剑意+武神；晶魄上，+200%+10%+5%+30%；最后还有50%几率左使之魄*3……哇塞……计宫主几乎被秒诶~</p>
            <p>攻击对象：防低血高即可。没试过对着小蛇小猪用这招，那太没意思了- -。一般来说后期小兵为最佳人选，追求刺激的话秒武罗也行- -</p>
        </div>

        </div>
        <FooterInfo></FooterInfo>  
    </div>
</template>
<script>
import FooterInfo from "../components/footer";
export default {
  components: {
    FooterInfo
  }
};
</script>

<style>
.godown {
  padding: 20px;
}
.godown .down-logo {
  padding: 20px;
}
.godown .down-jiesao {
  margin-top: 20px;
  width: 85%;
  padding: 20px;
  background-color: #fcfcfc;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}
.godown .down-jiesao .xinhao {
  display: flex;
  flex-direction: row;
}
.godown .down-jiesao .xinhao > img {
  width: 72px;
  height: 72px;
}
.godown .dnbtn {
  width: 160px;
  height: 40px;
}
.godown .down-jiesao .xinhao .xinhao-wenzi {
  margin-left: 20px;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li {
  display: flex;
  flex-direction: row;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:first-child {
  line-height: 30px;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:first-child p {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:first-child span {
  font-size: 12px;
  color: red;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:not(:first-child) {
  line-height: 22px;
}
.godown .down-jiesao .xinhao .xinhao-wenzi > ul > li:not(:first-child) span {
  margin-left: 20px;
}
.godown .down-jiesao .lianjie {
  position: relative;
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: red;
  border-radius: 8px;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 16px;

  margin-top: 20px;
}

.godown .down-jiesao .down_img {
  width: 100%;
  display: flex;
  padding: 20px;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.godown .down-jiesao .down_img img {
  width: 30%;
  height:100%;
}
.godown .down-jiesao .down_jieshao {
  width: 100%;
  padding: 20px;
}
.godown .down-jiesao .down_jieshao h1 {
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
}
</style>

